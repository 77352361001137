body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  scroll-behavior: smooth !important;
}
.MuiDialog-paperScrollPaper {
  background: linear-gradient(225deg, #034282, #ff8b03);
  width: 453px;
  /* height: 612px; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  padding: 4px;
  z-index: 10000000 !important;
}
.dialoge__content__section {
  background: linear-gradient(225deg, #034282, #ff8b03);
  border-radius: 30px;
  width: 100%;
  height: 100%;
  z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}
